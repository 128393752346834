import * as Sentry from "@sentry/browser";
import '../../../../resources/assets/_utilities/ts/env';
import {getCookie} from "typescript-cookie";

if (APP_ENV !== 'local') {
    Sentry.init({
        environment: APP_ENV,
        release: APP_RELEASE,
        integrations: [],
        tracesSampleRate: 1.0,
        tunnel: '/sentry-tunnel',
        transportOptions: {
            dsn: "...",
            headers: {
                'X-XSRF-TOKEN': getCookie('XSRF-TOKEN') || '',
            },
            tunnel: '/sentry-tunnel',
        }
    });
}
