window.addEventListener('load', () => {
    setTimeout(
        () => {
            document.querySelectorAll<HTMLStyleElement>('style[data-critical-url]')
                .forEach((styleElement) => {
                    const url = styleElement.getAttribute('data-critical-url')!;
                    const newStyle = document.createElement('link')
                    newStyle.href = url;
                    newStyle.rel = 'stylesheet';
                    newStyle.type = 'text/css';

                    document.body.appendChild(newStyle);
                })
        },
        1000)
});
