import {getCookie, setCookie} from "typescript-cookie";
import {cookiePrefix, cookieOptions} from "./config";
import {PrivacyAwareScript} from "./privacy-aware-script";

export class PrivacyService
{
    protected cookieName: string;
    public key: string;
    public name: string;
    public description: string;
    public omnipresent: boolean;

    constructor(key: string, name: string, description: string, omnipresent: boolean) {
        this.key = key;
        this.name = name;
        this.description = description;
        this.omnipresent = omnipresent;
        this.cookieName = `${cookiePrefix}-${key}`
    }

    public optIn(): Promise<void> {
        return new Promise<void>(
            (resolve) => {
                setCookie(this.cookieName, 'true', cookieOptions);
                this.enable();

                resolve();
            }
        )
    }

    public optOut(): Promise<void> {
        return new Promise<void>(
            (resolve) => {
                setCookie(this.cookieName, 'false', cookieOptions);
                this.disable();

                resolve();
            }
        )
    }

    public isPendingChoice(): boolean {
        return typeof getCookie(this.cookieName) === 'undefined';
    }

    public enable(): void {
        document.querySelectorAll<PrivacyAwareScript>('privacy-aware-script')
            .forEach((privacyAwareScriptEl): void => privacyAwareScriptEl.enable());
    }

    public disable(): void {
        document.querySelectorAll<PrivacyAwareScript>('privacy-aware-script')
            .forEach((privacyAwareScriptEl): void => privacyAwareScriptEl.disable());
    }

    public isEnabled(): boolean {
        return this.isDisabled() === false;
    }

    public isDisabled(): boolean {
        return this.isPendingChoice() || getCookie(this.cookieName) !== 'true';
    }
}
