/**
 * Browsers which don't support the latest CSP directives (eg Safari < 15.4)
 * do not support our non-blocking CSS loading strategy. This hack ensures
 * stylesheets will always be loaded.
 */

window.addEventListener('load', () => {
    document.querySelectorAll('link[rel="preload"][as="style"]')
        .forEach((element) => element.setAttribute('rel', 'stylesheet'));
});
